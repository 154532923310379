import React, { useEffect, useState } from 'react';
import './overlay.css';
import { isMobile } from 'react-device-detect';

function RotateOverlay() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const checkOrientation = () => {
      const isLandscape = window.matchMedia('(orientation: landscape)').matches;
      setShow(isLandscape);
    };

    window.addEventListener('resize', checkOrientation);
    window.addEventListener('orientationchange', checkOrientation);

    // initial check
    checkOrientation();

    return () => {
      window.removeEventListener('resize', checkOrientation);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, []);

  return show && isMobile ? (
    <div className="overlay">
      Please rotate your device to portrait mode.
    </div>
  ) : null;

}

export default RotateOverlay;
