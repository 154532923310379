import React, { useContext, useState } from "react";
import styled from "styled-components";
import { CarouselContext } from "pure-react-carousel";
import Category from "./Category";

function Navigation() {
  const carouselContext = useContext(CarouselContext);
  const [showCategory, setShowCategory] = useState(false);

  function handleClick(slideNumber) {
    setShowCategory(false);
    carouselContext.setStoreState({ currentSlide: slideNumber });
  }

  return (
    <Container>
      {!showCategory && (
        <Button onClick={() => setShowCategory(true)}>+</Button>
      )}
      {showCategory && <Category handleClick={handleClick} />}
    </Container>
  );
}

export default Navigation;

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Button = styled.button`
  position: absolute;
  bottom: 40px;
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50px;
  border: none;
  font-size: 1.2em;
  text-align: center;
  color: black;
  cursor: pointer;
`;
