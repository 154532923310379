import React from 'react';
import categories from '../data/categories.json';
import cards from '../data/cards.json';
import styled from 'styled-components'

const Category = (props) => {

    function buildIndex(cards) {
        let index = [null, null, null, null, null, null, null, null, null, null, null];
        //first card for each category
        for (let card of cards) {
            if (index[card.categoryId] === null) {
                index[card.categoryId] = card.cardNumber;
            }
        }
        return index;
    }

    const index = buildIndex(cards);

    const cat = categories.map((category) =>
        <CategoryContainer key={category.id}
            $bgColour={category.bgColour}
        >
            <Button onClick={() => props.handleClick(index[category.id])}>
                {category.category}
            </Button>
        </CategoryContainer>
    );

    return (
        <Container>
            {cat}
        </Container>
    )
}

export default Category;

const Button = styled.button`
    font-size: 1.2em;
    text-align: center;
    color: white;
    margin: 10px;
    background-color: transparent;
    border: none;
    outline: none; /* Optional: remove the focus outline if desired */
    cursor: pointer;
    width: 100%;
    height: 100%; /* This makes sure the button stretches over the whole container */
`;

const CategoryContainer = styled.div`
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    width: 100%;
    height: 100%; /*This makes sure the container stretches vertically. Adjust as needed. */
    background-color: ${props => props.$bgColour};
`;

const Container = styled.div`
    display: grid;
    grid-template-rows:  repeat(5, 1fr); /* Divide the screen into five columns */
    grid-template-columns: 1fr 1fr; /* Divide the screen into two rows */
    height: 100%;
    height: 100vh;
    height: 100dvh;
    width: 100%; /* Take the full width of the screen */
`;
