import React from "react"
import Carousel from './components/Carousel';
import styled from 'styled-components'
import RotateOverlay from './components/rotate-overlay/Rotate-overlay';

function App() {
  return (
    <AppContainer>
      <Frame>
        <Carousel />
      </Frame>
      <RotateOverlay />
    </AppContainer>
  );
}

export default App;

const Frame = styled.div`
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 800px;
}`

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

