import React from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import cards from '../data/cards.json';
import categories from '../data/categories.json';
import styled from 'styled-components'
import Navigation from './Navigation';


const Carousel = () => {

    const lookupColour = (id) => {
        const category = categories.find((category) => category.id === id);
        return category.bgColour;
    }

    const height = Math.min(window.innerHeight, 800);

    const slides = cards.map((card, i) =>
        <Slide key={card.cardNumber} index={i}>
           <Box>
                <Card $bgColour={lookupColour(card.categoryId)} $height={height}>
                    <CategoryText>{card.category}</CategoryText>
                    <IdeaText>{card.idea}</IdeaText>
                    <div>
                        <ExampleHeading>FOR EXAMPLE</ExampleHeading>
                        {card.examples.map((example,k) => <Examples key={k}>{example}</Examples>)}
                    </div>
                </Card>
                </Box>
        </Slide>
    );

    return (
        <>
            <CarouselProvider
                isIntrinsicHeight={true}
                totalSlides={slides.length}
                visibleSlides={1}
            >
                <Slider>
                    {slides}
                </Slider>
                <Navigation />
            </CarouselProvider>
        </>)
}

export default Carousel;

const Box = styled.div`
 padding: 0px 3px 0px 0px;
`;

const Card = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 13% 37% 50%;
    width: 100%;
    height: ${props => props.$height}px;
    background-color: ${props => props.$bgColour};
    text-align: left;
    color: #fff;
    margin: 0px;
    border-right: 3px solid  ${props => props.$bgColour === '#000000' ? '#fff' : '#000'};
`;


const CategoryText = styled.div`
    padding: 20px;    
    font-size: 1.1em;
    border-bottom: 1px solid #FFF;
`;

const IdeaText = styled.div`
    padding: 20px;
    font-size: 2.5em;
    border-bottom: 1px solid #FFF;
`;

const ExampleHeading = styled.div`
    text-align: center;
    padding-top: 15px;
    font-size: 1em;
`;

const Examples = styled.div`
    padding: 10px 20px;
    font-size: 1.2em;
`;



